/* App.css */

html {
    scroll-behavior: smooth;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s ease-out, transform 5s ease-out;
}

.hidden {
    opacity: 0;
    transform: translateY(0);
    transition: opacity 1s ease-out transform 5s ease-out;
}

@media (max-width: 768px) {
    /* Adjust header */
    .header-container {
        padding: 10px;
    }

    .header-logo {
        max-width: 80px;
    }

    /* Make sections responsive */
    .section {
        padding: 15px;
    }

    .section h2 {
        font-size: 1.5rem;
    }

    .section p {
        font-size: 0.9rem;
    }

    /* Services Section */
    .services-container {
        flex-direction: column;
    }

    .service-item {
        width: 100%;
        padding: 10px;
    }

    /* Footer adjustments */
    .footer-container {
        padding: 10px;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-links,
    .footer-social {
        flex-direction: column;
        gap: 10px;
    }
}