/* Footer.css */

.footer-container {
    background-color: #4B0082;
    color: white;
    padding: 20px;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer-left, .footer-center, .footer-right {
    flex: 1;
    display: flex;
    justify-content: center;
}

.footer-left {
    justify-content: flex-start;
}

.footer-right {
    justify-content: flex-end;
}

.footer-links {
    display: flex;
    gap: 15px;
}

.footer-links a, .footer-social a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover, .footer-social a:hover {
    color: #FFD700;
}

.footer-content p {
    margin: 0;
}

.footer-social {
    display: flex;
    gap: 20px;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-left, .footer-center, .footer-right {
        flex: none;
        display: flex;
        justify-content: center;
        gap: 15px;
        margin: 5px 0;
    }

    .footer-left p,
    .footer-center a,
    .footer-right a {
        font-size: 0.9rem;
    }

    .footer-center,
    .footer-right {
        flex-wrap: nowrap;
        gap: 20px;
    }
}