/* Services.css */

.services-section {
    padding: 50px;
    background-color: #f9f9f9;
    text-align: center;
}

.services-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #4B0082;
}

.services-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

.service-item {
    background-color: white;
    border-radius: 12px;
    padding: 25px;
    width: 320px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
    transform: translateY(-12px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.service-item h3 {
    font-size: 1.9rem;
    color: #4B0082;
    margin-bottom: 15px;
}

.service-item p {
    font-size: 1.1rem;
    color: #444;
}

.more-info-btn {
    padding: 12px 25px;
    background: linear-gradient(135deg, #4169E1, #2740bb);
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.more-info-btn:hover {
    background: linear-gradient(135deg, #2740bb, #1e3a8a);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    max-width: 600px;
    width: 100%;
    position: relative;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: left;
    animation: slideIn 0.4s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(100px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.modal h3 {
    font-size: 2rem;
    color: #4B0082;
    margin-bottom: 15px;
}

.modal ul {
    list-style-type: none;
    padding-left: 0;
    margin: 15px 0;
}

.modal ul li {
    margin-bottom: 10px;
    color: #555;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
}

.modal-contact-btn {
    background-color: #4169E1;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
    font-size: 1.1rem;
    font-weight: bold;
    transition: background-color 0.3 ease, box-shadow 0.3s ease;
}

.modal-contact-btn:hover {
    background: linear-gradient(135deg, #2740bb, #1e3a8a);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
}

@media (max-width: 1024px) {
    .services-section h2 {
        font-size: 2rem;
    }
    .service-item {
        width: 260px;
    }
}

/* Small Tablets and Landscape Mobile */
@media (max-width: 768px) {
    .services-container {
        flex-direction: column;
        gap: 15px;
    }
    .service-item {
        width: 90%;
    }
}

/* Mobile */
@media (max-width: 480px) {
    .services-section h2 {
        font-size: 1.8rem;
    }
    .service-item h3 {
        font-size: 1.6rem;
    }
    .service-item p {
        font-size: 1rem;
    }
    .more-info-btn {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}