/* Contact.css */

.contact-section {
    padding: 60px 20px;
    background-color: #f0f0f0;
    text-align: center;
}

.contact-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.contact-section h2 {
    font-size: 2.5rem;
    color: #4B0082;
    margin-bottom: 20px;
}

.contact-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #333;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 15px 15px 15px 45px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    position: relative;
    box-sizing: border-box;
}

.contact-form input:hover,
.contact-form textarea:hover{
    box-shadow: 0 0 10px rgba(65, 105, 225, 0.5);
}

.contact-form button {
    background-color: #4169E1;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
    overflow: hidden;
}

.contact-form button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.15);
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    transition: transform 0.6s ease;
    z-index: 1;
}

.contact-form button:hover {
    background-color: #2740bb;
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(39, 64, 187, 0.5);
}

.contact-form button:active::after {
    transform: translate(-50%, -50%) scale(1);
}

.contact-form button:hover::after {
    transform: translate(-50%, -50%) scale(1.5);
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #4169E1;
    box-shadow: 0 0 10px rgba(65, 105, 225, 0.5);
    outline: none;
}

.contact-info p {
    margin-top: 30px;
    font-size: 1rem;
}

.contact-info a {
    color: #4169E1;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.form-group {
    position: relative;
    margin-bottom: 20px;
}

.form-group input {
    padding-left: 15px;
}

.form-group textarea {
    padding-left: 15px;
}

.form-group input:focus ~ .icon,
.form-group textarea:focus ~ .icon {
    color: #4169E1;
}
.form-group label {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 1rem;
    color: #888;
    pointer-events: none;
    transition: all 0.3s ease;
}

.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:focus + label,
.form-group textarea:not(:placeholder-shown) + label {
    top: -20px;
    left: 15px;
    font-size: 0.8rem;
    color: #4169E1;
}

@media (max-width: 768px) {
    .contact-content {
        padding: 20px;
    }

    .contact-section h2 {
        font-size: 1.8rem;
    }

    .contact-section p {
        font-size: 1rem;
    }

    .contact-form button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    .contact-form input,
    .contact-form textarea {
        padding: 12px;
    }

    .contact-info p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .contact-section {
        padding: 20px 10px;
    }

    .contact-section h2 {
        font-size: 1.5rem;
    }

    .contact-section p {
        font-size: 0.9rem;
    }

    .contact-form button {
        padding: 7px 14px;
        font-size: 0.8rem;
    }

    .contact-info p {
        font-size: 0.8rem;
    }
}
