/* About.css */

.about-section {
    padding: 60 20px;
    background: linear-gradient(135deg, #4B0082, #4169E1);
    color: white;
    text-align: center;
}

.about-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 15px;
}

.about-section h2 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    color: #FFFFFF;
    font-family: 'Poppins' sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
}

.about-section p {
    font-size: 1.3rem;
    color: #F0F0F0;
    margin-bottom: 25px;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
}

.about-section p:last-child {
    font-size: 1.4rem;
    font-weight: bold;
}

.about-content p:first-child, .about-content p:last-child {
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
}

@media (max-width: 768px) {
    .about-section {
        padding: 30px 15px;
    }

    .about-content {
        padding: 20px;
    }

    .about-section h2 {
        font-size: 1.8rem;
    }

    .about-section p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .about-section {
        padding: 20px 10px;
    }

    .about-section h2 {
        font-size: 1.5rem;
    }

    .about-section p {
        font-size: 0.9rem;
    }
}