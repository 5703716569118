/* Header.css */

.header-container {
    background: linear-gradient(135deg, #4B0082, #4169E1);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    min-height: 70vh;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.logo img {
    max-width: 250px;
    margin-bottom: 30px;
    transition: transform 0.3s ease;
}

.logo img:hover {
    transform: scale(1.1);
}

.hero-text {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-text h1 {
    font-size: 4rem;
    margin-bottom: 20px;
    text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1s ease-in-out;
}

.hero-text p {
    font-size: 2rem;
    margin-bottom: 40px;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    width: 600px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    min-height: 50px;
}

.flying-wizard {
    width: 180px;
    position: absolute;
    top: 0;
    left: 0;
    animation: fly 20s linear infinite, fadeInOut 6s ease-in-out infinite;
}

@keyframes fly {
    0% {transform: translate(5vw, 5vh); }
    25% {transform: translate(70vw, 10vh); }
    50% {transform: translate(85vw, 5vh); }
    75% {transform: translate(40vw, 15vh); }
    100% {transform: translate(5vw, 5vh); }
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

.cta-btn, .contact-btn {
    padding: 15px 40px;
    border: 2px solid #FFD700;
    border-radius: 30px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;
    color: #FFD700;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    display: inline-block;
    overflow: hidden;
}

.cta-btn::before, .contact-btn::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 255, 0, 0.6);
    z-index: -1;
    transition: top 1.5s ease;
}

.cta-btn:hover::before, .contact-btn:hover::before {
    top: 0;
}

.cta-btn:hover, .contact-btn:hover {
    color: white;
    box-shadow: 0 8px 20px rgba(0, 255, 0, 0.5);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.cta-btn:hover::after, .contact-btn:hover::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(0, 255, 0, 0.4), rgba(0, 128, 0, 0));
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.cta-btn:hover::after, .contact-btn:hover::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

@media (max-height: 700px) {
    .header-container {
        min-height: 60vh;
    }

    .logo img {
        max-width: 150px;
    }

    .hero-text h1 {
        font-size: 2.5rem;
    }

    .hero-text p {
        font-size: 1.2rem;
        width: 80%;
    }

    .cta-btn, .contact-btn {
        padding: 8px 15px;
        font-size: 0.9rem;
    }

    .flying-wizard {
        width: 100px;
    }
}

@media (max-height: 500px) {
    .header-container {
        min-height: 50vh;
    }

    .hero-text {
        min-height: 100px;
    }
}

@media (max-width: 768px) {
    .header-container {
        min-height: 50vh;
        padding: 15px;
    }

    .logo img {
        max-width: 120px;
    }

    .hero-text h1 {
        font-size: 2.2rem;
    }

    .hero-text p {
        font-size: 1.1rem;
        width: 90%;
    }

    .cta-btn, .contact-btn {
        padding: 10px 20px;
        font-size: 0.9rem;
    }

    .flying-wizard {
        width: 80px;
    }
}

@media (max-width: 480px) {
    .header-container {
        min-height: 40vh;
        padding: 10px;
    }

    .logo img {
        max-width: 100px;
    }

    .hero-text h1 {
        font-size: 1.8rem;
    }

    .hero-text p {
        font-size: 1rem;
        width: 100%;
    }

    .cta-btn, .contact-btn {
        padding: 8px 15px;
        font-size: 0.8rem;
    }

    .flying-wizard {
        width: 60px;
    }
}